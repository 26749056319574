import * as React from 'react';
import queryString from 'query-string';
import styled from 'styled-components';
import { RouteComponentProps } from '@reach/router';
import { AuthenticatedContent } from '@humancollective/human-dashboard';
import { IconNames } from '@blueprintjs/icons';
import { Button, Icon, Intent } from '@blueprintjs/core';

import { getCampaign, getToken } from '../utilities/firebase';
import { API } from '../utilities';
import { Campaign, Token, TokenStatus } from '@humancollective/seedz-shared';
import Loading from '@humancollective/human-dashboard/shared/src/components/Loading';

const StyledQRPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

type QRProps = RouteComponentProps;

interface QRPageStatus {
  consumed?: boolean;
  error?: Error;
  token?: Token;
  campaign?: Campaign;
}

const QRPage: React.FunctionComponent<QRProps> = ({ location }) => {
  const [status, setStatus] = React.useState<QRPageStatus>({});
  const [tokenId, setTokenId] = React.useState<string | undefined>();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    try {
      if (!location || !location.search) {
        throw new Error('Something went wrong');
      }

      const urlParams = queryString.parse(location.search);
      setTokenId(urlParams.token as string);
    } catch (error) {
      setStatus({ error });
    }
  }, []);

  React.useEffect(() => {
    initialize();
  }, [tokenId]);

  const initialize = async () => {
    try {
      if (!tokenId) {
        throw new Error('Token ID not Found');
      }

      const nextToken = await getToken(tokenId);
      if (!nextToken) {
        throw new Error('Token not Found');
      }
      if (nextToken.status !== TokenStatus.Redeemed) {
        throw new Error('This token is not redeemable');
      }

      const nextCampaign = await getCampaign(nextToken.campaign);
      if (!nextCampaign) {
        throw new Error('Campaign not found');
      }
      if (!nextCampaign.isRedeemable) {
        throw new Error('This campaign is not redeemable');
      }

      setStatus({
        token: nextToken,
        campaign: nextCampaign,
      });
      setLoading(false);
    } catch (error) {
      setStatus({ error });
      setLoading(false);
    }
  };

  const consumeToken = async () => {
    try {
      setLoading(true);

      const consumeTokenRequest = await API.post({
        url: '/token/consume',
        data: { tokenId },
      });

      if (consumeTokenRequest.status !== 'CONSUMED') {
        throw new Error('Something went wrong, please try again.');
      }

      setStatus({
        consumed: true,
      });
      setLoading(false);
    } catch (error) {
      setStatus({ error });
      setLoading(false);
    }
  };

  const { consumed, error, token, campaign } = status;

  return loading ? (
    <Loading />
  ) : (
    <AuthenticatedContent>
      <StyledQRPage>
        {token && campaign && !error && !consumed && (
          <>
            <img
              src={`https://${
                process.env.GATSBY_IMGIX_URL
              }${campaign.markerImage || '/utils/seedz-icon.png'}?h=100`}
            />
            <h2>Redeem {campaign.name}?</h2>
            <Button
              text="Redeem"
              large={true}
              intent={Intent.SUCCESS}
              onClick={() => consumeToken(token)}
            />
          </>
        )}

        {error && (
          <>
            <Icon icon={IconNames.ERROR} iconSize={100} />
            <h2>{error.message}</h2>
          </>
        )}

        {consumed && !error && (
          <>
            <Icon icon={IconNames.CLEAN} iconSize={100} />
            <h2>Success!</h2>
          </>
        )}
      </StyledQRPage>
    </AuthenticatedContent>
  );
};

export default QRPage;
